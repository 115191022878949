exports.components = {
  "component---src-soplica-wedel-template-tsx": () => import("./../../../src/soplica-wedel/template.tsx" /* webpackChunkName: "component---src-soplica-wedel-template-tsx" */),
  "component---src-templates-aes-tsx": () => import("./../../../src/templates/aes.tsx" /* webpackChunkName: "component---src-templates-aes-tsx" */),
  "component---src-templates-contest-regulations-page-tsx": () => import("./../../../src/templates/contest-regulations-page.tsx" /* webpackChunkName: "component---src-templates-contest-regulations-page-tsx" */),
  "component---src-templates-contests-akcja-gorzka-closed-tsx": () => import("./../../../src/templates/contests/akcja-gorzka/closed.tsx" /* webpackChunkName: "component---src-templates-contests-akcja-gorzka-closed-tsx" */),
  "component---src-templates-contests-akcja-gorzka-expired-tsx": () => import("./../../../src/templates/contests/akcja-gorzka/expired.tsx" /* webpackChunkName: "component---src-templates-contests-akcja-gorzka-expired-tsx" */),
  "component---src-templates-contests-akcja-gorzka-thank-you-tsx": () => import("./../../../src/templates/contests/akcja-gorzka/thank-you.tsx" /* webpackChunkName: "component---src-templates-contests-akcja-gorzka-thank-you-tsx" */),
  "component---src-templates-contests-akcja-gorzka-winner-tsx": () => import("./../../../src/templates/contests/akcja-gorzka/winner.tsx" /* webpackChunkName: "component---src-templates-contests-akcja-gorzka-winner-tsx" */),
  "component---src-templates-contests-halloween-closed-tsx": () => import("./../../../src/templates/contests/halloween/closed.tsx" /* webpackChunkName: "component---src-templates-contests-halloween-closed-tsx" */),
  "component---src-templates-contests-halloween-expired-tsx": () => import("./../../../src/templates/contests/halloween/expired.tsx" /* webpackChunkName: "component---src-templates-contests-halloween-expired-tsx" */),
  "component---src-templates-contests-halloween-thank-you-tsx": () => import("./../../../src/templates/contests/halloween/thank-you.tsx" /* webpackChunkName: "component---src-templates-contests-halloween-thank-you-tsx" */),
  "component---src-templates-contests-halloween-winner-tsx": () => import("./../../../src/templates/contests/halloween/winner.tsx" /* webpackChunkName: "component---src-templates-contests-halloween-winner-tsx" */),
  "component---src-templates-contests-konkurs-wezyk-closed-tsx": () => import("./../../../src/templates/contests/konkurs-wezyk/closed.tsx" /* webpackChunkName: "component---src-templates-contests-konkurs-wezyk-closed-tsx" */),
  "component---src-templates-contests-konkurs-wezyk-expired-tsx": () => import("./../../../src/templates/contests/konkurs-wezyk/expired.tsx" /* webpackChunkName: "component---src-templates-contests-konkurs-wezyk-expired-tsx" */),
  "component---src-templates-contests-konkurs-wezyk-main-tsx": () => import("./../../../src/templates/contests/konkurs-wezyk/main.tsx" /* webpackChunkName: "component---src-templates-contests-konkurs-wezyk-main-tsx" */),
  "component---src-templates-contests-konkurs-wezyk-thank-you-tsx": () => import("./../../../src/templates/contests/konkurs-wezyk/thank-you.tsx" /* webpackChunkName: "component---src-templates-contests-konkurs-wezyk-thank-you-tsx" */),
  "component---src-templates-contests-konkurs-wezyk-winner-tsx": () => import("./../../../src/templates/contests/konkurs-wezyk/winner.tsx" /* webpackChunkName: "component---src-templates-contests-konkurs-wezyk-winner-tsx" */),
  "component---src-templates-contests-valentines-closed-tsx": () => import("./../../../src/templates/contests/valentines/closed.tsx" /* webpackChunkName: "component---src-templates-contests-valentines-closed-tsx" */),
  "component---src-templates-contests-valentines-expired-tsx": () => import("./../../../src/templates/contests/valentines/expired.tsx" /* webpackChunkName: "component---src-templates-contests-valentines-expired-tsx" */),
  "component---src-templates-contests-valentines-thank-you-tsx": () => import("./../../../src/templates/contests/valentines/thank-you.tsx" /* webpackChunkName: "component---src-templates-contests-valentines-thank-you-tsx" */),
  "component---src-templates-contests-valentines-winner-tsx": () => import("./../../../src/templates/contests/valentines/winner.tsx" /* webpackChunkName: "component---src-templates-contests-valentines-winner-tsx" */),
  "component---src-templates-contests-wedding-contest-closed-tsx": () => import("./../../../src/templates/contests/wedding-contest/closed.tsx" /* webpackChunkName: "component---src-templates-contests-wedding-contest-closed-tsx" */),
  "component---src-templates-contests-winter-contest-closed-tsx": () => import("./../../../src/templates/contests/winter-contest/closed.tsx" /* webpackChunkName: "component---src-templates-contests-winter-contest-closed-tsx" */),
  "component---src-templates-contests-winter-contest-expired-tsx": () => import("./../../../src/templates/contests/winter-contest/expired.tsx" /* webpackChunkName: "component---src-templates-contests-winter-contest-expired-tsx" */),
  "component---src-templates-contests-winter-contest-thank-you-tsx": () => import("./../../../src/templates/contests/winter-contest/thank-you.tsx" /* webpackChunkName: "component---src-templates-contests-winter-contest-thank-you-tsx" */),
  "component---src-templates-contests-winter-contest-winner-tsx": () => import("./../../../src/templates/contests/winter-contest/winner.tsx" /* webpackChunkName: "component---src-templates-contests-winter-contest-winner-tsx" */),
  "component---src-templates-dessert-page-tsx": () => import("./../../../src/templates/dessert-page.tsx" /* webpackChunkName: "component---src-templates-dessert-page-tsx" */),
  "component---src-templates-flavored-page-tsx": () => import("./../../../src/templates/flavored-page.tsx" /* webpackChunkName: "component---src-templates-flavored-page-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-label-generator-page-tsx": () => import("./../../../src/templates/label-generator-page.tsx" /* webpackChunkName: "component---src-templates-label-generator-page-tsx" */),
  "component---src-templates-label-occasions-page-tsx": () => import("./../../../src/templates/label-occasions-page.tsx" /* webpackChunkName: "component---src-templates-label-occasions-page-tsx" */),
  "component---src-templates-label-presentation-page-tsx": () => import("./../../../src/templates/label-presentation-page.tsx" /* webpackChunkName: "component---src-templates-label-presentation-page-tsx" */),
  "component---src-templates-label-summary-page-tsx": () => import("./../../../src/templates/label-summary-page.tsx" /* webpackChunkName: "component---src-templates-label-summary-page-tsx" */),
  "component---src-templates-label-thanks-you-page-tsx": () => import("./../../../src/templates/label-thanks-you-page.tsx" /* webpackChunkName: "component---src-templates-label-thanks-you-page-tsx" */),
  "component---src-templates-lemon-page-tsx": () => import("./../../../src/templates/lemon-page.tsx" /* webpackChunkName: "component---src-templates-lemon-page-tsx" */),
  "component---src-templates-links-page-tsx": () => import("./../../../src/templates/links-page.tsx" /* webpackChunkName: "component---src-templates-links-page-tsx" */),
  "component---src-templates-log-in-tsx": () => import("./../../../src/templates/log-in.tsx" /* webpackChunkName: "component---src-templates-log-in-tsx" */),
  "component---src-templates-mulled-page-tsx": () => import("./../../../src/templates/mulled-page.tsx" /* webpackChunkName: "component---src-templates-mulled-page-tsx" */),
  "component---src-templates-old-polish-page-tsx": () => import("./../../../src/templates/old-polish-page.tsx" /* webpackChunkName: "component---src-templates-old-polish-page-tsx" */),
  "component---src-templates-order-labels-page-tsx": () => import("./../../../src/templates/order-labels-page.tsx" /* webpackChunkName: "component---src-templates-order-labels-page-tsx" */),
  "component---src-templates-password-recovery-tsx": () => import("./../../../src/templates/password-recovery.tsx" /* webpackChunkName: "component---src-templates-password-recovery-tsx" */),
  "component---src-templates-password-reset-tsx": () => import("./../../../src/templates/password-reset.tsx" /* webpackChunkName: "component---src-templates-password-reset-tsx" */),
  "component---src-templates-product-details-page-tsx": () => import("./../../../src/templates/product-details-page.tsx" /* webpackChunkName: "component---src-templates-product-details-page-tsx" */),
  "component---src-templates-product-listing-page-tsx": () => import("./../../../src/templates/product-listing-page.tsx" /* webpackChunkName: "component---src-templates-product-listing-page-tsx" */),
  "component---src-templates-recipe-listing-tsx": () => import("./../../../src/templates/recipe-listing.tsx" /* webpackChunkName: "component---src-templates-recipe-listing-tsx" */),
  "component---src-templates-recipe-page-tsx": () => import("./../../../src/templates/recipe-page.tsx" /* webpackChunkName: "component---src-templates-recipe-page-tsx" */),
  "component---src-templates-soplica-gorzka-tsx": () => import("./../../../src/templates/soplica-gorzka.tsx" /* webpackChunkName: "component---src-templates-soplica-gorzka-tsx" */),
  "component---src-templates-soplica-szlachetna-tsx": () => import("./../../../src/templates/soplica-szlachetna.tsx" /* webpackChunkName: "component---src-templates-soplica-szlachetna-tsx" */),
  "component---src-templates-spirit-page-tsx": () => import("./../../../src/templates/spirit-page.tsx" /* webpackChunkName: "component---src-templates-spirit-page-tsx" */),
  "component---src-templates-tag-listing-tsx": () => import("./../../../src/templates/tag-listing.tsx" /* webpackChunkName: "component---src-templates-tag-listing-tsx" */),
  "component---src-templates-video-listing-tsx": () => import("./../../../src/templates/video-listing.tsx" /* webpackChunkName: "component---src-templates-video-listing-tsx" */),
  "component---src-templates-video-page-tsx": () => import("./../../../src/templates/video-page.tsx" /* webpackChunkName: "component---src-templates-video-page-tsx" */),
  "component---src-templates-vodka-page-tsx": () => import("./../../../src/templates/vodka-page.tsx" /* webpackChunkName: "component---src-templates-vodka-page-tsx" */)
}

